<template>
<section>
    <div class="container">
        <div class="row mb-4 pb-4">
            <div class="col-12">
                <h1 class="mb-4 pb-4">Contacto</h1>
                <p>Tenemos deseos de sumar personas y recibir sus consultas por este medio. </p>
            </div>
        </div>
        <form action="https://admin.observadatos.org/contact-me" method="post">
        <div class="row mb-4">
           <div class="col-sm-10">
               <p class="mb-0">Nombre</p>
               <input type="text" name="od_name">
            </div>        
        </div>
        <div class="row mb-4">
           <div class="col-sm-10">
               <p class="mb-0">Correo Electrónico</p>
               <input type="email" name="od_email">
            </div>        
        </div>
        <div class="row mb-4">
           <div class="col-sm-10">
               <p class="mb-0">Mensaje</p>
               <textarea class="od_textarea"  name="od_text"></textarea>
            </div>        
        </div>
        <div class="row mb-4">
           <div class="col-sm-10 text-right">
               <button class="od_search_button">Enviar mensaje</button>
            </div>        
        </div>
         </form>
    </div>
    </section>
</template>